import React from "react";
import { useContentStyles } from "./Content.styles";
import { Box } from "@mui/material";

export interface IContentProps {
    children: JSX.Element;
}

function Content(props: IContentProps) {
    const styles = useContentStyles();

    return <Box sx={styles.content}>{props.children}</Box>;
}

export default Content;
