import React from "react";
import { Navigate } from "react-router-dom";

function SignInCallback() {
    let locationBeforeSignIn = sessionStorage.getItem("location-before-sign-in");
    if (!locationBeforeSignIn) {
        locationBeforeSignIn = "/";
    }

    let content = <Navigate to={locationBeforeSignIn} />;

    return <React.Fragment>{content}</React.Fragment>;
}

export default SignInCallback;
