import { AuthProvider, AuthProviderProps } from "oidc-react";
import React from "react";
import { getSettings } from "../common/settingsProvider";

export interface ICustomerAdminWebAuthProviderProps {
    children: JSX.Element;
}

let settings = getSettings();

const oidcConfig: AuthProviderProps = {
    onBeforeSignIn: () => {
        sessionStorage.setItem("location-before-sign-in", window.location.pathname + window.location.search);
        return "";
    },
    authority: settings.idpAuthority,
    clientId: settings.idpClientId,
    responseType: "code",
    redirectUri: settings.mainSiteBaseUrl + "/sign-in-callback",
    postLogoutRedirectUri: settings.mainSiteBaseUrl,
    automaticSilentRenew: true,
    autoSignIn: true,
    scope: "openid profile offline_access",
    loadUserInfo: true,
};

function CustomerAdminWebAuthProvider(props: ICustomerAdminWebAuthProviderProps) {
    return <AuthProvider {...oidcConfig}>{props.children}</AuthProvider>;
}

export default CustomerAdminWebAuthProvider;
