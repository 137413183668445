import { useTranslation } from "../localization/useTranslation";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../common/api/apiShared.ts";
import { Currency, PurchaseSaveModel, PurchaseViewModel } from "./Purchase.types.ts";
import { isNullOrWhitespace } from "../common/stringOperations.ts";
import { validateEmail } from "../common/emailValidator.ts";
import { Result } from "../common/api/result.ts";

export function usePurchase() {
    const { translateString } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [saveModel, setSaveModel] = useState<PurchaseSaveModel>(initializeSaveModel());
    const [showConfirmation, setShowConfirmation] = useState(false);

    const productCustomerId = new URLSearchParams(window.location.search).get("productCustomerId");
    const url = "/Purchase/" + productCustomerId;
    let { isLoading, data, refetch } = useQuery(url, () => get<PurchaseViewModel>(url));

    useEffect(() => {
        if (data) {
            setSaveModel({
                ...saveModel,
                productCustomerId: data.productCustomerId,
                numberOfGroupEntities: data.numberOfGroupEntities,
            });
        }
        // eslint-disable-next-line
    }, [data]);

    const saveMutation = useMutation(purchase);
    const purchaseProduct = () => {
        saveMutation.mutateAsync(undefined, {
            onSuccess: (resp: Result<{ url: string }>) => {},
        });
    };

    function onSaveModelChange(saveModel: PurchaseSaveModel) {
        let temp = { ...saveModel };
        setSaveModel(temp);
    }

    const onPurchasePlans = async () => {
        await purchaseProduct();
        setShowConfirmation(true);
    };

    const refetchData = () => {
        refetch();
    };

    return {
        isLoading,
        data,
        steps: getSteps(),
        activeStep,
        setActiveStep,
        saveModel,
        setSaveModel,
        isPurchaseValid: isPurchaseValid(),
        purchaseInProgress: saveMutation.isLoading,
        onSaveModelChange,
        onPurchasePlans,
        showConfirmation,
        refetchData,
    };

    function purchase() {
        return post<{ url: string }>("/Purchase", saveModel);
    }

    function initializeSaveModel(): PurchaseSaveModel {
        return {
            productCustomerId: "",
            legalVATNumber: null,
            address: "",
            postalCode: "",
            city: "",
            country: "",
            countryCode: "",
            fullName: "",
            email: "",
            language: "",
            numberOfGroupEntities: 0,
            selectedPlans: [],
            currency: Currency.DKK,
        };
    }
    function isPurchaseValid() {
        const hasSelectedAnyPlans = saveModel.selectedPlans.length > 0;
        return hasSelectedAnyPlans && isAllPaymentInfoFilled();
    }

    function isAllPaymentInfoFilled() {
        return (
            !isNullOrWhitespace(saveModel.address) &&
            !isNullOrWhitespace(saveModel.postalCode) &&
            !isNullOrWhitespace(saveModel.city) &&
            !isNullOrWhitespace(saveModel.country) &&
            !isNullOrWhitespace(saveModel.countryCode) &&
            !isNullOrWhitespace(saveModel.fullName) &&
            !isNullOrWhitespace(saveModel.email) &&
            validateEmail(saveModel.email)
        );
    }

    function getSteps() {
        return [
            {
                name: translateString("choosePlan"),
                error: false,
                visited: false,
                stepNumber: 0,
            },
            {
                name: translateString("addOns"),
                error: false,
                visited: false,
                stepNumber: 1,
            },
            {
                name: translateString("paymentInfo"),
                error: false,
                visited: false,
                stepNumber: 2,
            },
        ];
    }
}
