import { post } from "../../../common/api/apiShared";
import { ITrialDialogProps } from "./TrialDialog.tsx";
import { useMutation } from "react-query";
import { Result } from "../../../common/api/result.ts";
import { useState } from "react";
import { getSettings } from "../../../common/settingsProvider.ts";

export function useTrialDialog(props: ITrialDialogProps) {
    const [showTrialCreatedContent, setShowTrialCreatedContent] = useState(false);

    const returnUrl = new URLSearchParams(window.location.search).get("returnUrl") ?? getSettings().compliancePlatformUrl;

    const trialMutation = useMutation(trialApi);
    const beginTrial = () => {
        trialMutation.mutateAsync(undefined, {
            onSuccess: (resp: Result<{ url: string }>) => {
                props.refetch();
                setShowTrialCreatedContent(true);
            },
        });
    };

    return {
        beginTrial,
        inProgress: trialMutation.isLoading,
        showTrialCreatedContent,
        returnUrl,
    };

    function trialApi() {
        return post<{ url: string }>(`/Purchase/${props.platformId}/trial`, props.planType);
    }
}
