import { Box, Divider } from "@mui/material";
import { DotLegalHeader, DotLegalLink } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { Currency, PlanCostModelViewModel, PlanType } from "../../Purchase.types.ts";
import { useTranslation } from "../../../localization/useTranslation.ts";

export interface PaymentSummeryProps {
    selectedPlans: Array<PlanType>;
    allPlans: Array<PlanCostModelViewModel>;
    currency: Currency;
}

function PaymentSummery(props: PaymentSummeryProps) {
    const { translatePlanType } = useTranslation();

    const getPriceForPlan = (plan: PlanCostModelViewModel): number => {
        return props.currency === Currency.DKK ? plan!.totalCostDKK! : plan!.totalCostEUR!;
    };

    const getTotalPrice = () => {
        if (props.selectedPlans && props.selectedPlans.length > 0) {
            return props.selectedPlans.reduce((acc, plan) => {
                const foundPlan = props.allPlans.find((x) => x.planType === plan);
                return acc + getPriceForPlan(foundPlan!);
            }, 0);
        }
        return 0;
    };

    return (
        <Box sx={(theme) => ({ border: `2px solid ${theme.palette.primary.main}`, borderRadius: "10px", p: "10px", height: "100%" })}>
            <>
                <DotLegalHeader headerStyle={"medium"} marginBottom={1}>
                    Summery
                </DotLegalHeader>

                {props.selectedPlans && props.selectedPlans.length > 0 && (
                    <>
                        {props.selectedPlans.map((selectedPlan) => {
                            const foundPlan = props.allPlans.find((x) => x.planType === selectedPlan);
                            return (
                                <Box key={selectedPlan.toString()} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <DotLegalHeader headerStyle={"small"} marginBottom={1} fontWeight={400}>
                                        {translatePlanType(selectedPlan)}
                                    </DotLegalHeader>

                                    <DotLegalHeader headerStyle={"small"} marginBottom={1}>
                                        {getPriceForPlan(foundPlan!)} {props.currency === Currency.DKK ? "DKK" : "EUR"}/month
                                    </DotLegalHeader>
                                </Box>
                            );
                        })}
                    </>
                )}

                <Divider sx={(theme) => ({ backgroundColor: theme.palette.primary.main, my: "8px" })} />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DotLegalHeader headerStyle={"small"} marginBottom={1}>
                        Total
                    </DotLegalHeader>

                    <DotLegalHeader headerStyle={"small"} marginBottom={1}>
                        {getTotalPrice()} {props.currency === Currency.DKK ? "DKK" : "EUR"}/month
                    </DotLegalHeader>
                </Box>

                <Box sx={{ "& a.MuiBox-root": { textDecoration: "underline !important" }, "& .MuiTypography-root": { fontSize: 10 } }}>
                    <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                        All prices are exclusive of VAT and any taxes. Monthly payments, no commitment.
                        <br />
                        By completing the purchase, you agree to .legal&nbsp;
                        <DotLegalLink linkColor={"primary"} to={"https://www.dotlegal.com/en/terms-of-service"}>
                            Terms of Service
                        </DotLegalLink>
                        &nbsp;and&nbsp;
                        <DotLegalLink linkColor={"primary"} to={"https://www.dotlegal.com/en/privacy-policy"}>
                            Privacy Policy
                        </DotLegalLink>
                        .
                    </DotLegalHeader>
                </Box>
            </>
        </Box>
    );
}

export default PaymentSummery;
