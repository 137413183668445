import { DotLegalButton, DotLegalHeader, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { blueCheck } from "../../common/icons";
import { usePlanSelectorStyles } from "./PlanSelector.styles";
import { Currency } from "../Purchase.types";

export interface IPlanSelectorProps {
    header: string;
    tooltip: string;
    costs: number | string;
    currency: Currency;
    subHeader: string | ReactNode;
    features: Array<ReactNode>;
    showTrialButton: boolean;
    trialIsOngoing?: boolean;
    trialExpired?: boolean;
    purchased?: boolean;
    isSelected?: boolean;
    note?: ReactNode;
    onChooseClick?: () => void;
    onTrialClick?: () => void;
    purchaseButtonText?: string;
    isAddon?: boolean;
}
function PlanSelector(props: IPlanSelectorProps) {
    const styles = usePlanSelectorStyles();

    function getCosts() {
        if (isNaN(Number(props.costs.toString()))) {
            return props.costs;
        }

        return (
            <Box>
                {props.costs} {props.currency === Currency.EUR ? "EUR" : "DKK"}
                <Box sx={(theme) => ({ fontSize: theme.typography.pxToRem(12), color: theme.palette.primary.dark })} component={"span"}>
                    {" "}
                    /month
                </Box>
            </Box>
        );
    }

    function getTrialButtonToolTip() {
        if (props.trialExpired) {
            return `Your free trial has expired. To continue using ${props.header}, you will need to upgrade your plan.`;
        }

        if (props.trialIsOngoing) {
            return "You already have an ongoing trial.";
        }

        return undefined;
    }

    return (
        <DotLegalPaper
            fullHeight
            backgroundColor={props.isSelected || props.purchased ? "lightBlue" : undefined}
            sx={(theme) => ({ outline: props.isSelected || props.purchased ? `2px solid ${theme.palette.primary.main}` : "none" })}
        >
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Box sx={{ marginBottom: -1 }}>
                    <DotLegalHeader marginBottom={0} headerStyle={"small"} toolTip={props.tooltip}>
                        {props.header}
                    </DotLegalHeader>
                    <Typography sx={(theme) => ({ fontSize: theme.typography.pxToRem(32), color: theme.palette.primary.dark, fontWeight: 600 })}>
                        {getCosts()}
                    </Typography>

                    <Box sx={{ height: props.isAddon ? 30 : 50 }}>
                        <DotLegalHeader fontWeight={400} headerStyle={"extraSmall"}>
                            {props.subHeader}
                        </DotLegalHeader>
                    </Box>

                    {props.features.map((x) => (
                        <Box sx={styles.logo} key={x?.toString()}>
                            <Box>{blueCheck}</Box>
                            <DotLegalHeader fontWeight={400} headerStyle="extraSmall">
                                {x}
                            </DotLegalHeader>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {props.showTrialButton && (
                        <DotLegalButton
                            toolTip={getTrialButtonToolTip()}
                            onClick={props.onTrialClick}
                            disabled={props.trialIsOngoing || props.trialExpired}
                            buttonType="secondary"
                            fullWidth
                        >
                            Start free trial
                        </DotLegalButton>
                    )}

                    {props.note && props.note}
                    <DotLegalButton onClick={props.onChooseClick} disabled={props.purchased} buttonType="primary">
                        {props.purchased ? "Current plan" : props.purchaseButtonText ? props.purchaseButtonText : props.isSelected ? "Chosen" : "Choose"}
                    </DotLegalButton>
                </Box>
            </Box>
        </DotLegalPaper>
    );
}

export default PlanSelector;
