import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "../localization/useTranslation.ts";
import {
    DotLegalButton,
    DotLegalCoreStepper,
    DotLegalFullScreenSpinner,
    DotLegalHeader,
    DotLegalLink,
    useIsOnLargeScreen,
    useIsOnMediumScreen,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import { usePurchase } from "./Purchase.hooks.ts";
import { compliancePlatformLogo, rightArrow } from "../common/icons.tsx";
import { maxBy } from "../common/images/arrayOperations.ts";
import PaymentInformationStep from "./paymentInformationStep/PaymentInformationStep.tsx";
import AddonStep from "./addonStep/AddonStep.tsx";
import PlanStep from "./planStep/PlanStep.tsx";
import Confirmation from "./confirmation/confirmation.tsx";
import { getSettings } from "../common/settingsProvider.ts";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function Purchase() {
    const isSmallScreen = useIsOnSmallScreen();
    const isMediumScreen = useIsOnMediumScreen();
    const isLargeScreen = useIsOnLargeScreen();
    const { translateString } = useTranslation();
    const {
        isLoading,
        data,
        steps,
        activeStep,
        setActiveStep,
        saveModel,
        setSaveModel,
        isPurchaseValid,
        purchaseInProgress,
        onPurchasePlans,
        onSaveModelChange,
        showConfirmation,
        refetchData,
    } = usePurchase();

    const isLastStep = activeStep === maxBy(steps, "stepNumber").stepNumber;
    const isPaymenInfoStep = activeStep === 2;

    function getStepContent() {
        switch (activeStep) {
            case 0:
                return <PlanStep plans={data?.plans ?? []} saveModel={saveModel} onChange={onSaveModelChange} refetch={refetchData} />;
            case 1:
                return <AddonStep plans={data?.plans ?? []} saveModel={saveModel} onChange={onSaveModelChange} />;
            case 2:
                return (
                    <PaymentInformationStep
                        onChange={(model) => setSaveModel(model)}
                        saveModel={saveModel}
                        plans={data?.plans ?? []}
                        companyName={data?.companyName ?? ""}
                    />
                );
        }
    }

    const returnUrl = new URLSearchParams(window.location.search).get("returnUrl") ?? getSettings().compliancePlatformUrl;

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
            <Box sx={{ padding: 3, pl: "36px", pb: 1, pt: 0 }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", "& img": { height: 58 } }}>
                    <Box sx={{ "& svg": { height: 42, width: "auto" } }}>{compliancePlatformLogo}</Box>
                    <Box sx={(theme) => ({ display: "flex", zIndex: 1000, marginLeft: "-6px", "& .MuiSvgIcon-root": { fill: theme.palette.primary.dark } })}>
                        <NavigateBeforeIcon />
                        <DotLegalLink linkColor={"primary-dark"} to={returnUrl} openExternalLinkInSameTab>
                            Back
                        </DotLegalLink>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}
                mt={isSmallScreen ? "0px" : isMediumScreen ? "-24px" : "-62px"}
            >
                <Box
                    sx={(theme) => ({
                        "& .MuiTypography-root": { fontSize: theme.typography.pxToRem(48) },
                    })}
                >
                    <DotLegalHeader marginBottom={1} headerStyle={"xxxl"}>
                        {translateString("chooseYourRightPlan")}
                    </DotLegalHeader>
                </Box>
                <DotLegalHeader fontWeight={400} headerStyle={"medium"} marginBottom={1}>
                    {translateString("purchaseSubHeader")}
                </DotLegalHeader>

                <Box sx={{ textDecoration: "underline", "&:hover": { textDecoration: "underline" } }}>
                    <DotLegalLink linkColor={"primary-dark"} to={"https://www.dotlegal.com/en/pricing"}>
                        <DotLegalHeader headerStyle={"small"} marginBottom={1}>
                            {translateString("seePlansAndFeatures")}
                        </DotLegalHeader>
                    </DotLegalLink>
                </Box>

                <Box
                    sx={{
                        px: isSmallScreen ? 0 : isMediumScreen ? "10%" : isLargeScreen ? "15%" : "18%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        "& svg": {
                            fill: "common.white",
                        },
                        "& .MuiButton-root": {
                            "&:hover": {
                                "& svg": {
                                    transform: "translateX(5px)",
                                    transition: "0.2s",
                                },
                            },
                        },
                    }}
                >
                    {isLoading ? (
                        <Box mt={6}>
                            <DotLegalFullScreenSpinner />
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mb: 2,
                                    visibility: showConfirmation ? "hidden" : "visible",
                                }}
                            >
                                {!isSmallScreen && (
                                    <Box sx={{ visibility: activeStep === 0 || showConfirmation ? "hidden" : "visible" }}>
                                        <DotLegalButton btnLength={185} buttonType="primary" onClick={() => setActiveStep(activeStep - 1)}>
                                            <Box sx={{ transform: "rotate(180deg)" }}>{rightArrow}</Box>
                                            &nbsp;
                                            {translateString("previous")}
                                        </DotLegalButton>
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        width: isSmallScreen ? "100%" : isMediumScreen ? "80%" : "65%",
                                        "& .MuiStep-root:last-of-type:hover": {
                                            cursor: "grab",
                                        },
                                    }}
                                >
                                    <DotLegalCoreStepper
                                        steps={steps}
                                        activeStepIndex={activeStep}
                                        onStepClick={(step) => {
                                            setActiveStep(step);
                                        }}
                                    />
                                </Box>
                                {!isSmallScreen && (
                                    <DotLegalButton
                                        disabled={isPaymenInfoStep && !isPurchaseValid}
                                        btnLength={185}
                                        buttonType="primary"
                                        toolTip={isPaymenInfoStep && !isPurchaseValid ? "All fields except for VAT Number are required" : ""}
                                        onClick={isLastStep ? () => onPurchasePlans() : () => setActiveStep(activeStep + 1)}
                                    >
                                        {isPaymenInfoStep ? translateString("completePurchase") : translateString("continue")}
                                        {!isLastStep && (
                                            <>
                                                &nbsp;
                                                <Box>{rightArrow}</Box>
                                            </>
                                        )}
                                    </DotLegalButton>
                                )}
                            </Box>
                            <Box mb={3}>{showConfirmation ? <Confirmation inProgress={purchaseInProgress} /> : getStepContent()}</Box>
                        </>
                    )}
                </Box>
            </Box>
            <Box sx={{ alignSelf: "center" }}>
                <DotLegalHeader center headerStyle={"extraSmall"} marginBottom={0}>
                    {translateString("priceInformation")}
                </DotLegalHeader>
            </Box>
        </Box>
    );
}

export default Purchase;
