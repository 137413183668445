import { Box, Grid } from "@mui/material";
import PlanSelector from "../planSelector/PlanSelector";
import { Currency, PlanCostModelViewModel, PlanType } from "../Purchase.types";
import { DotLegalHeader, DotLegalLink } from "@dotlegal/dotlegal-ui-components";
import usePlanStepHooks from "../planStep/PlanStep.hooks";
import { IPlanStep } from "../planStep/PlanStep";
import { useTranslation } from "../../localization/useTranslation";
import React from "react";

export interface IAddonStep extends Omit<IPlanStep, "refetch"> {}

function AddonStep(props: IAddonStep) {
    const { onSelectedPlanChange } = usePlanStepHooks(props.saveModel, props.onChange);
    const { translatePlanType } = useTranslation();

    const hasMultipleGroupEntities = props.saveModel.numberOfGroupEntities > 1;

    const addons = [PlanType.SingleSignOn, PlanType.UserManagementPremium, PlanType.CustomLists, PlanType.Declarations, PlanType.OrganizationalManagement];

    function onChooseClick(planType: PlanType) {
        if (hasMultipleGroupEntities) {
            window.open("https://www.dotlegal.com/en/about/contact-us", "_blank");
        } else {
            onSelectedPlanChange(planType);
        }
    }

    function getSelectorByPlanType(planType: PlanType) {
        const plan = props.plans.find((x) => x.planType === planType)!;
        const costs = hasMultipleGroupEntities ? "Get a quote" : getCost(plan);

        switch (plan.planType) {
            case PlanType.SingleSignOn:
                return (
                    <PlanSelector
                        header={translatePlanType(plan.planType)}
                        tooltip={
                            "Our software supports Single Sign-On (SSO), allowing users to access the platform securely with their existing credentials. SSO streamlines the login process so users can authenticate through their organisation’s identity provider, reducing the need to manage multiple usernames and passwords."
                        }
                        costs={"Free"}
                        currency={props.saveModel.currency}
                        subHeader={"More security without extra costs."}
                        features={[
                            "Increased security",
                            "Simplified access management",
                            "Seamless user experience",
                            readMore("https://www.dotlegal.com/en/product/single-sign-on"),
                        ]}
                        showTrialButton={false}
                        purchased={!plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onSelectedPlanChange(plan.planType)}
                        isAddon
                    />
                );
            case PlanType.UserManagementPremium:
                return (
                    <PlanSelector
                        header={translatePlanType(plan.planType)}
                        tooltip={
                            "With advanced user and role management, you have the power to design highly specific user roles as an administrator. Control both the features and data each user can access, ensuring that your compliance work is both efficient and secure. You can create as many roles as you want. You can also use the add-on to transfer responsibility from one user to another."
                        }
                        costs={costs}
                        currency={props.saveModel.currency}
                        subHeader={"Advanced user permissions and roles."}
                        features={[
                            "Flexible role design",
                            "Granular access control",
                            "Focused user interfaces",
                            readMore("https://www.dotlegal.com/en/product/advanced-user-management"),
                        ]}
                        showTrialButton={false}
                        purchased={!plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onChooseClick(plan.planType)}
                        isAddon
                        purchaseButtonText={hasMultipleGroupEntities ? "Contact" : undefined}
                    />
                );
            case PlanType.CustomLists:
                return (
                    <PlanSelector
                        header={translatePlanType(plan.planType)}
                        tooltip={
                            "Get a tailored overview of your compliance documentation. With Custom Lists, you can create dynamic lists that draw from all your data within the platform – entirely on your own terms. Fast and intuitive. Customise your own lists for the platform and export to Excel."
                        }
                        costs={costs}
                        currency={props.saveModel.currency}
                        subHeader={"Customise lists and export to Excel."}
                        features={[
                            "Fast and dynamic data extraction",
                            "Automatic updated lists",
                            "Lists that match your workflow",
                            readMore("https://www.dotlegal.com/en/product/custom-lists"),
                        ]}
                        showTrialButton={false}
                        purchased={!plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onChooseClick(plan.planType)}
                        isAddon
                        purchaseButtonText={hasMultipleGroupEntities ? "Contact" : undefined}
                    />
                );
            case PlanType.Declarations:
                return (
                    <PlanSelector
                        header={translatePlanType(plan.planType)}
                        tooltip={
                            "Take full control of your declarations, all in one place.. Gain a clear overview of your progress across multiple declarations like ISAE3402, ISAE3000, and ISO27001."
                        }
                        costs={costs}
                        currency={props.saveModel.currency}
                        subHeader={"Handle declarations such as ISAE3402."}
                        features={[
                            "Maintain declarations with ease",
                            "Systematic approach",
                            "Give auditors direct access",
                            readMore("https://www.dotlegal.com/en/product/declaration"),
                        ]}
                        showTrialButton={false}
                        purchased={!plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onChooseClick(plan.planType)}
                        isAddon
                        purchaseButtonText={hasMultipleGroupEntities ? "Contact" : undefined}
                    />
                );
            case PlanType.OrganizationalManagement:
                return (
                    <PlanSelector
                        header={translatePlanType(plan.planType)}
                        tooltip={
                            "Make documentation for multiple group companies in the same environment. Make a combination of group specific documentation and company specific documentation."
                        }
                        costs={"Get a quote"}
                        currency={props.saveModel.currency}
                        subHeader={"Manage your entire organisation."}
                        features={[
                            "Centralized documentation",
                            "Shared resources & processes",
                            "Flexible management",
                            readMore("https://www.dotlegal.com/en/product/organisational-management"),
                        ]}
                        showTrialButton={false}
                        purchased={!plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => window.open("https://www.dotlegal.com/en/about/contact-us", "_blank")}
                        purchaseButtonText="Contact"
                        isAddon
                    />
                );
        }
    }

    function readMore(url: string) {
        return (
            <DotLegalHeader fontWeight={400} headerStyle="extraSmall">
                Read more{" "}
                <Box component={"span"} sx={{ "& a.MuiBox-root": { textDecoration: "underline !important" } }}>
                    <DotLegalLink linkColor={"primary"} to={url}>
                        here
                    </DotLegalLink>
                </Box>
            </DotLegalHeader>
        );
    }

    function getCost(plan: PlanCostModelViewModel) {
        if (props.saveModel.currency === Currency.DKK) {
            return plan.totalCostDKK!;
        } else {
            return plan.totalCostEUR!;
        }
    }

    return (
        <Grid container columnSpacing={2} rowSpacing={2}>
            {addons.map((x) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={x.toString()}>
                    {getSelectorByPlanType(x)}
                </Grid>
            ))}
        </Grid>
    );
}

export default AddonStep;
