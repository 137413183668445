import React from "react";
import { Routes, Route } from "react-router-dom";
import SignInCallback from "./auth/SignInCallback";
import ErrorPage from "./errorPage/ErrorPage";
import { useTranslation } from "./localization/useTranslation";
import Purchase from "./purchase/Purchase";

export interface IRouterSwitch {}

function RouterSwitch(props: IRouterSwitch) {
    const { translateString } = useTranslation();

    return (
        <React.Fragment>
            <Routes>
                <Route path="/purchase" element={<Purchase />} />
                <Route path="/sign-in-callback" element={<SignInCallback />} />
                <Route path="/" element={<div>FrontPage</div>} />
                <Route path="*" element={<ErrorPage errorMessage={translateString("pageNotFound")} />} />
            </Routes>
        </React.Fragment>
    );
}

export default RouterSwitch;
