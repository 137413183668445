import { DotLegalButton, DotLegalFullScreenSpinner, DotLegalHeader, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import Confirmation from "./confirmation.svg";
import { Box } from "@mui/material";

export interface ConfirmationStepProps {
    inProgress: boolean;
}

function ConfirmationStep(props: ConfirmationStepProps) {
    const returnUrl = new URLSearchParams(window.location.search).get("returnUrl")!;

    return (
        <Box sx={{}}>
            <DotLegalPaper fullHeight>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", my: 2, gap: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
                        <img src={Confirmation} alt={Confirmation}></img>
                    </Box>
                    <DotLegalHeader headerStyle="medium">Thank you for your order!</DotLegalHeader>
                    {props.inProgress ? (
                        <>
                            <DotLegalHeader fontWeight={400} headerStyle="medium">
                                Please wait while we are processing your purchase.
                            </DotLegalHeader>

                            <Box mb={2}>
                                <DotLegalFullScreenSpinner />
                            </Box>
                        </>
                    ) : (
                        <>
                            <DotLegalHeader fontWeight={400} headerStyle="medium">
                                Your purchase is now complete. You will receive an order confirmation by email. You can start using the new features right away.
                            </DotLegalHeader>
                            <DotLegalHeader marginBottom={5} fontWeight={400} headerStyle="medium">
                                If you have any questions, please feel free to contact our support on{" "}
                                <Box sx={(theme) => ({ color: theme.palette.primary.main })} component={"a"} target="_blank" href="tel:+4570270127">
                                    +45 7027 0127
                                </Box>{" "}
                                or{" "}
                                <Box sx={(theme) => ({ color: theme.palette.primary.main })} component={"a"} href="mailto:support@dotlegal.com">
                                    support@dotlegal.com
                                </Box>
                            </DotLegalHeader>
                            <Box sx={{ mb: 3 }}>
                                <DotLegalButton buttonType="primary" href={returnUrl}>
                                    Return to platform
                                </DotLegalButton>
                            </Box>
                        </>
                    )}
                </Box>
            </DotLegalPaper>
        </Box>
    );
}

export default ConfirmationStep;
