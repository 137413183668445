import React, { createContext, useCallback } from "react";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { PlanType } from "../purchase/Purchase.types.ts";

interface ITranslationFile {
    language: string;
    translation: any;
}

export const noTranslationText = "No translation found for";

export const setupi18n = (files: ITranslationFile[], defaultLanguage: string) => {
    const resources: any = {};
    files.forEach((f) => {
        resources[f.language] = { translation: f.translation };
    });

    i18n.use(initReactI18next).init({
        debug: false,
        lng: defaultLanguage,
        resources,
        returnEmptyString: false,
        parseMissingKeyHandler: (key: string) => {
            return `${noTranslationText} "${key}"`;
        },
        interpolation: { escapeValue: false },
    });
};

export const TranslationContext = createContext<{
    translateString: (key: string, interpolation?: any) => string;
    translationExists: (key: string) => boolean;
    translateNumber: (num: number) => string;
    translateDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
    translateCountry: (countryCode: string) => string;
    translateLanguage: (countryCode: string) => string;
    changeLanguage: (languageCode: string) => void;
    getBrowserLanguage: () => string;
    getChosenLanguage: () => string;
    getChosenDateFormat: () => string;
    translatePlanType: (planType: PlanType) => string;
}>({
    translateString: () => "",
    translationExists: () => false,
    translateNumber: () => "",
    translateDate: () => "",
    translateLanguage: () => "",
    translateCountry: () => "",
    changeLanguage: () => {},
    getBrowserLanguage: () => "",
    getChosenLanguage: () => "",
    getChosenDateFormat: () => "",
    translatePlanType: () => "",
});

export const TranslationProvider: React.FunctionComponent<{
    children: any;
    onLanguageChanged?: (languageCode: string) => void;
}> = ({ children, onLanguageChanged }) => {
    const { t } = useTranslation("", { useSuspense: false });
    const queryClient = useQueryClient();

    const getBrowserLanguage = () => navigator.language ?? "en";
    const getChosenLanguage = () => i18n.language;
    const getChoseDateFormat = () => i18n.language;
    const translateNumber = useCallback((num: number) => num.toLocaleString(i18n.language), []);

    const translateDate = useCallback((date: Date) => {
        if (!date) {
            return "";
        }

        const localDate = new Date(date);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(localDate);
        let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(localDate);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(localDate);
        if (getChoseDateFormat() === "da") {
            return `${da}-${mo}-${ye}`;
        }
        return `${ye}-${mo}-${da}`;
    }, []);

    const changeLanguage = (language: string) => {
        const isPdf = new URLSearchParams(window.location.search).get("isPdf");
        let current = getChosenLanguage();

        if (current !== language && !isPdf) {
            localStorage.setItem("language", language);
            i18n.changeLanguage(language);

            queryClient.clear();
        }
    };

    const translateLanguage = (countryCode: string): string => {
        const json: any = t("languages", { returnObjects: true });
        return json[countryCode.toUpperCase()];
    };

    const countries: any = t("countries", { returnObjects: true });
    const translateCountry = (countryCode: string): string => {
        return countries[countryCode];
    };

    const translationExists = (key: string): boolean => {
        return !t(key).startsWith(noTranslationText);
    };

    const translatePlanType = (planType: PlanType) => {
        switch (planType) {
            case PlanType.GRCPlatformCoreModule:
                return ".legal GRC Platform";
            case PlanType.DataProtection:
                return "Data Protection";
            case PlanType.InformationSecurity:
                return "Information & Cyber Security";
            case PlanType.VendorManagement:
                return "Vendor Management";
            case PlanType.SingleSignOn:
                return "Single Sign-On";
            case PlanType.UserManagementPremium:
                return "Advanced User Management";
            case PlanType.CustomLists:
                return "Custom lists";
            case PlanType.Declarations:
                return "Declarations";
            case PlanType.OrganizationalManagement:
                return "Organisational Management";
            default:
                return "";
        }
    };

    /* const columnTypes: any = t("countries", {returnObjects: true});
    const translateListColumnTypes = (type: string): string => {
        return countries[countryCode];
    }; */

    return (
        <TranslationContext.Provider
            value={{
                translateString: t,
                translationExists,
                translateNumber,
                translateDate,
                translateCountry,
                translateLanguage,
                changeLanguage,
                getBrowserLanguage: getBrowserLanguage,
                getChosenLanguage: getChosenLanguage,
                getChosenDateFormat: getChoseDateFormat,
                translatePlanType,
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};
