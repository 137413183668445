import { createSxStyles } from "../../common/createSxStyles";
import { useTheme } from "@mui/material";

export const usePlanSelectorStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        logo: {
            "& svg": {
                fill: theme.palette.primary.main,
                width: 16 + "px",
                height: 16 + "px",
            },
            display: "flex",
            gap: 1,
            marginRight: theme.spacing(0.5),
            marginBottom: -1,
        },
    });
};
