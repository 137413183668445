import { Box } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import errorBackground from "./dotLegal-errorpage.png";
import { DotLegalHeader, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";

export interface IErrorPageProps {
    errorMessage: string;
}

function ErrorPage(props: IErrorPageProps) {
    const isSmallScreen = useIsOnSmallScreen();

    return (
        <Box
            sx={(theme) => ({
                display: "flex",
                height: "100%",
                justifyContent: "center",
                [theme.breakpoints.up("md")]: {
                    backgroundImage: `url(${errorBackground})`,
                },
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "50% 50%",
            })}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: isSmallScreen ? "center" : "space-around",
                    alignItems: "center",
                }}
            >
                <React.Fragment>
                    <DotLegalHeader color="secondary" headerStyle="xxl" marginTop={10} marginBottom={0}>
                        {props.errorMessage}
                    </DotLegalHeader>
                </React.Fragment>

                <Box
                    sx={(theme) => ({
                        marginBottom: theme.spacing(2),
                        textAlign: "center",
                    })}
                >
                    <DotLegalHeader color="secondary" headerStyle="medium" marginTop={10}>
                        <Trans
                            i18nKey={"errorContactDotLegal"}
                            components={{
                                email: <a href="mailto:support@dotlegal.com">support@dotlegal.com</a>,
                                phone: <a href="tel:+45 7027 0127">+45 7027 0127</a>,
                            }}
                        />
                    </DotLegalHeader>
                </Box>
            </Box>
        </Box>
    );
}

export default ErrorPage;
