export interface PurchaseViewModel {
    companyId: string;
    productCustomerId: string;
    accountId: string;
    companyName: string;
    numberOfGroupEntities: number;
    plans: Array<PlanCostModelViewModel>;
}

export interface PlanCostModelViewModel {
    planType: PlanType;
    canPurchasePlan: boolean;
    totalCostEUR: number | null;
    totalCostDKK: number | null;
    hasOngoingTrial: boolean;
    isTrialExpired: boolean;
}

export interface PurchaseSaveModel {
    productCustomerId: string;
    legalVATNumber?: string | null;
    address: string;
    postalCode: string;
    city: string;
    country: string | undefined;
    countryCode: string | undefined;
    fullName: string;
    email: string;
    language: string;
    numberOfGroupEntities: number;
    selectedPlans: PlanType[];
    currency: Currency;
}

export enum Currency {
    DKK = 1,
    EUR = 2,
}

export enum PlanType {
    PrivacyStandard = 100,
    PrivacyPro = 103,
    PrivacyResearch = 104,
    PrivacyStarter = 105,
    PrivacyEssential = 106,

    GRCPlatformCoreModule = 600,
    DataProtection = 601,
    InformationSecurity = 602,
    VendorManagement = 603,
    Research = 604,
    Declarations = 605, // add-on
    SingleSignOn = 606, // add-on
    UserManagementPremium = 607, // add-on
    CustomLists = 608, // add-on
    OrganizationalManagement = 609, // add-on
}
