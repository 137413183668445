import { Box, Grid, Typography } from "@mui/material";
import PlanSelector from "../planSelector/PlanSelector";
import { Currency, PlanCostModelViewModel, PlanType, PurchaseSaveModel } from "../Purchase.types";
import { DotLegalHeader, DotLegalLink } from "@dotlegal/dotlegal-ui-components";
import usePlanStepHooks from "./PlanStep.hooks";
import TrialDialog from "./trialDialog/TrialDialog";
import React from "react";

export interface IPlanStep {
    plans: Array<PlanCostModelViewModel>;
    saveModel: PurchaseSaveModel;
    onChange: (saveModel: PurchaseSaveModel) => void;
    refetch: () => void;
}

export function getCost(currentCurrency: Currency, plan: PlanCostModelViewModel) {
    if (currentCurrency === Currency.DKK) {
        return plan.totalCostDKK!;
    } else {
        return plan.totalCostEUR!;
    }
}

function PlanStep(props: IPlanStep) {
    const { onSelectedPlanChange, selectedPlanToTrial, setSelectedPlanToTrial } = usePlanStepHooks(props.saveModel, props.onChange);
    const hasMultipleGroupEntities = props.saveModel.numberOfGroupEntities > 1;

    const subscriptions = [PlanType.GRCPlatformCoreModule, PlanType.InformationSecurity, PlanType.DataProtection, PlanType.VendorManagement];

    function onChooseClick(planType: PlanType) {
        if (hasMultipleGroupEntities) {
            window.open("https://www.dotlegal.com/en/about/contact-us", "_blank");
        } else {
            onSelectedPlanChange(planType);
        }
    }

    const groupCompaniesSubheader = () => {
        return (
            <>
                You have registered{" "}
                <Box component={"span"} sx={{ fontWeight: 700 }}>
                    {props.saveModel.numberOfGroupEntities}
                </Box>{" "}
                {props.saveModel.numberOfGroupEntities > 1 ? "group companies." : "group company."}
            </>
        );
    };

    function getSelectorByPlanType(planType: PlanType) {
        const plan = props.plans.find((x) => x.planType === planType)!;
        const costs = hasMultipleGroupEntities ? "Get a quote" : getCost(plan);

        switch (plan.planType) {
            case PlanType.GRCPlatformCoreModule:
                return (
                    <PlanSelector
                        header={".legal GRC Platform"}
                        tooltip={
                            ".legal GRC Platform provides access to the core functionality of our compliance platform. It offers a sample of each module, including data protection, information & cyber security and vendor management. While the free plan gives you a solid foundation, please note that certain features come with usage limits. "
                        }
                        costs={"Free"}
                        currency={props.saveModel.currency}
                        subHeader={"Use it for as long as you want. Credit card not required."}
                        features={[
                            "Unlimited Users & Admins",
                            "Onboarding in App",
                            "Dashboard",
                            "Compliance Task Management",
                            "Risk Management",
                            "Vendor Management",
                            "Asset Management",
                            "Policies & Procedures",
                            "Audits & Assessments",
                            "Document Management",
                            readMore(),
                        ]}
                        showTrialButton={false}
                        purchased
                        note={note()}
                    />
                );
            case PlanType.DataProtection:
                return (
                    <PlanSelector
                        header={"Data Protection"}
                        tooltip={
                            "Ensure compliance with the GDPR regulation. Let our software lead you through your compliance documentation, leverage automation for assistance, and collaborate seamlessly with colleagues."
                        }
                        costs={costs}
                        currency={props.saveModel.currency}
                        subHeader={groupCompaniesSubheader()}
                        features={[
                            "Everything from Free",
                            "Unlimited Access to all Features within Data Protection",
                            "Processing Activity Templates",
                            "Catalogue of standard GDPR Activities",
                            "GDPR Incident Log",
                            "Catalouge of GDPR Risk Scenarios",
                            "Personal Onboarding & Support ",
                            readMore(),
                        ]}
                        showTrialButton={plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onChooseClick(plan.planType)}
                        purchased={!plan.canPurchasePlan}
                        onTrialClick={() => setSelectedPlanToTrial(plan.planType)}
                        trialExpired={plan.isTrialExpired}
                        trialIsOngoing={plan.hasOngoingTrial}
                        purchaseButtonText={hasMultipleGroupEntities ? "Contact" : undefined}
                    />
                );
            case PlanType.InformationSecurity:
                return (
                    <PlanSelector
                        header={"Information- & Cyber Security"}
                        tooltip={
                            "Ensure compliance with information and cybersecurity standards. Use our module to meet NIS2 directive requirements and adhere to ISO 27000 series standards. Streamline your compliance processes, leverage automation for efficiency, and collaborate effortlessly with your team."
                        }
                        costs={costs}
                        currency={props.saveModel.currency}
                        subHeader={groupCompaniesSubheader()}
                        features={[
                            "Everything from Free",
                            "Unlimited Access to all Features within Information & Cyber Security",
                            "Asset Templates",
                            "Catalogue of NIS2 activities",
                            "Catalogue of ISO 27001 & ISO 27002 activities ",
                            "Catalogue of NIS2 Risk Scenarios ",
                            "Personal Onboarding & Support ",
                            readMore(),
                        ]}
                        showTrialButton={plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onChooseClick(plan.planType)}
                        purchased={!plan.canPurchasePlan}
                        onTrialClick={() => setSelectedPlanToTrial(plan.planType)}
                        trialExpired={plan.isTrialExpired}
                        trialIsOngoing={plan.hasOngoingTrial}
                        purchaseButtonText={hasMultipleGroupEntities ? "Contact" : undefined}
                    />
                );
            case PlanType.VendorManagement:
                return (
                    <PlanSelector
                        header={"Vendor Management"}
                        tooltip={
                            "Gain insight and control over your vendors’ handling of data. Use the module for efficient vendor risk management and comprehensive assessments."
                        }
                        costs={costs}
                        currency={props.saveModel.currency}
                        subHeader={groupCompaniesSubheader()}
                        features={[
                            "Everything from Free",
                            "Unlimited Access to all Features within Vendor Management",
                            "Unlimited Assessments",
                            "Data Processor Audit Template",
                            "Audit Groups",
                            "Data Processor Risk Classification Template",
                            "Personal Onboarding & Support",
                            readMore(),
                        ]}
                        showTrialButton={plan.canPurchasePlan}
                        isSelected={props.saveModel.selectedPlans.includes(plan.planType)}
                        onChooseClick={() => onChooseClick(plan.planType)}
                        purchased={!plan.canPurchasePlan}
                        onTrialClick={() => setSelectedPlanToTrial(plan.planType)}
                        trialExpired={plan.isTrialExpired}
                        trialIsOngoing={plan.hasOngoingTrial}
                        purchaseButtonText={hasMultipleGroupEntities ? "Contact" : undefined}
                    />
                );
        }
    }

    function readMore() {
        return (
            <DotLegalHeader fontWeight={400} headerStyle="extraSmall">
                Read about all features{" "}
                <Box component={"span"} sx={{ "& a.MuiBox-root": { textDecoration: "underline !important" } }}>
                    <DotLegalLink linkColor={"primary"} to={"https://www.dotlegal.com/en/pricing#feature-list"}>
                        here
                    </DotLegalLink>
                </Box>
            </DotLegalHeader>
        );
    }

    function note() {
        return (
            <Typography sx={{ fontSize: 10, "& a.MuiBox-root": { textDecoration: "underline !important" } }}>
                Please note that certain features come with{" "}
                <DotLegalLink linkColor={"primary-dark"} to={"https://www.dotlegal.com/en/pricing#feature-list"}>
                    usage limits
                </DotLegalLink>
                .
            </Typography>
        );
    }

    function getCost(plan: PlanCostModelViewModel) {
        if (props.saveModel.currency === Currency.DKK) {
            return plan.totalCostDKK!;
        } else {
            return plan.totalCostEUR!;
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                {subscriptions.map((x) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={x.toString()}>
                        {getSelectorByPlanType(x)}
                    </Grid>
                ))}
            </Grid>

            {selectedPlanToTrial && (
                <TrialDialog
                    onDialogClose={() => setSelectedPlanToTrial(undefined)}
                    planType={selectedPlanToTrial}
                    platformId={props.saveModel.productCustomerId}
                    refetch={props.refetch}
                />
            )}
        </>
    );
}

export default PlanStep;
