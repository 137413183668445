import React from "react";
export const dataControllerRecordIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.208 15.321">
        <g id="Group_3263" data-name="Group 3263" transform="translate(-1129.814 -513.179)">
            <g id="Group_3262" data-name="Group 3262" transform="translate(285.814 -396.821)">
                <g id="Group_3261" data-name="Group 3261" transform="translate(844.5 910.5)">
                    <g id="Group_3260" data-name="Group 3260">
                        <path
                            id="Path_2792"
                            data-name="Path 2792"
                            d="M854.463,912.5h1.245v13.076H844.5V912.5h1.245"
                            transform="translate(-844.5 -911.255)"
                            fill="none"
                            stroke="#7387f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_2794"
                            data-name="Path 2794"
                            d="M847.745,914.5H846.5v10.585h8.717V914.5h-1.245"
                            transform="translate(-845.255 -912.009)"
                            fill="#efeffa"
                            stroke="#7387f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_2793"
                            data-name="Path 2793"
                            d="M852.859,911.745a1.245,1.245,0,0,0-2.491,0H848.5v1.868h6.227v-1.868Z"
                            transform="translate(-846.009 -910.5)"
                            fill="#efeffa"
                            stroke="#7387f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_428"
                    data-name="Line 428"
                    x2="5"
                    transform="translate(847.5 915.5)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_429"
                    data-name="Line 429"
                    x2="5"
                    transform="translate(847.5 917)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_430"
                    data-name="Line 430"
                    x2="5"
                    transform="translate(847.5 918.5)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_431"
                    data-name="Line 431"
                    x2="2.802"
                    transform="translate(847.5 920)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const dotlegalLinkIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.361" height="13.195" viewBox="0 0 12.361 11.195">
        <g id="Group_7636" data-name="Group 7636" transform="translate(-1324.715 -334.682)">
            <path
                id="Path_12989"
                data-name="Path 12989"
                d="M-16621.969,18722.6s1.818-1.773,2.463-1.348a8.568,8.568,0,0,1,1.559,1.816s-.049,1.672-.74,1.969a18.827,18.827,0,0,0-2.76,2.443s-1.574-.062-1.893-.516"
                transform="translate(17953.887 -18385.506)"
                fill="none"
                stroke="#efeffa"
                strokeWidth="2"
            />
            <path
                id="Path_12990"
                data-name="Path 12990"
                d="M23.684,1.262,23.128.706a2.416,2.416,0,0,0-3.413,0L18.529,1.892a.167.167,0,0,0,.066.277L20,2.635a.166.166,0,0,0,.17-.04l.642-.643a.88.88,0,0,1,1.21,0l.411.411a.859.859,0,0,1,0,1.21L20.057,5.954a.85.85,0,0,1-.382.219.86.86,0,0,1-.828-.219l-.235-.235L18.594,5.7a.169.169,0,0,0-.119-.053.152.152,0,0,0-.12.049l-.94.94a.167.167,0,0,0,0,.236l.331.33a2.387,2.387,0,0,0,.851.547,2.422,2.422,0,0,0,2.224-.269,2.389,2.389,0,0,0,.337-.279l2.526-2.526a2.42,2.42,0,0,0,0-3.413m-.236,3.177L20.923,6.965a2.1,2.1,0,0,1-2.208.47,2.06,2.06,0,0,1-.733-.47l-.213-.213.7-.7.14.14a1.194,1.194,0,0,0,1.682,0l.513-.513,1.868-1.868a1.19,1.19,0,0,0,0-1.682l-.411-.411a1.191,1.191,0,0,0-1.682,0l-.57.57-1.056-.349,1-1a2.082,2.082,0,0,1,2.941,0l.556.556a2.086,2.086,0,0,1,0,2.941"
                transform="translate(1312.438 335.049)"
                fill="#efeffa"
                stroke="#618cf5"
                strokeWidth="0.5"
            />
            <path
                id="Path_12992"
                data-name="Path 12992"
                d="M-16621.814,18744.162c-.316-.527-2.191-.359-2.191-.359l-3.113,3.176.115.178a1.5,1.5,0,0,0,.406,1.7,13.61,13.61,0,0,0,1.477.973s1.938-.828,2.086-.973"
                transform="translate(17953 -18405)"
                fill="none"
                stroke="#efeffa"
                strokeWidth="1"
            />
            <path
                id="Path_12991"
                data-name="Path 12991"
                d="M5.8,19.265,4.386,18.8a.166.166,0,0,0-.17.04l-.642.643a.88.88,0,0,1-1.21,0l-.411-.411a.859.859,0,0,1,0-1.211l2.38-2.38a.848.848,0,0,1,.382-.219.859.859,0,0,1,.829.219l.236.237.016.018a.168.168,0,0,0,.119.054.181.181,0,0,0,.121-.049l.94-.94a.166.166,0,0,0,0-.235l-.331-.331a2.383,2.383,0,0,0-.851-.547,2.421,2.421,0,0,0-2.224.268,2.385,2.385,0,0,0-.338.279L.706,16.759a2.416,2.416,0,0,0,0,3.413l.556.556a2.417,2.417,0,0,0,3.413,0l1.187-1.187a.167.167,0,0,0-.066-.276M4.439,20.492a2.082,2.082,0,0,1-2.941,0l-.556-.556A2.082,2.082,0,0,1,.941,17l2.526-2.526a2.035,2.035,0,0,1,.291-.24A2.091,2.091,0,0,1,5.676,14a2.051,2.051,0,0,1,.733.47l.213.213-.7.7-.14-.14a1.193,1.193,0,0,0-1.682,0L1.716,17.625a1.19,1.19,0,0,0,0,1.682l.411.411a1.19,1.19,0,0,0,1.682,0l.57-.57,1.055.349Z"
                transform="translate(1324.965 324.193)"
                fill="#efeffa"
                stroke="#618cf5"
                strokeWidth="0.5"
            />
        </g>
    </svg>
);

export const blueCheck = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.823 10.436">
        <g id="tick-kopi" transform="translate(0.5 -67.428)">
            <g id="Group_918" data-name="Group 918" transform="translate(0 67.997)">
                <path
                    id="Path_1240"
                    data-name="Path 1240"
                    d="M12.567,68.184a.638.638,0,0,0-.9,0l-7.64,7.64L1.088,72.888a.638.638,0,0,0-.9.9l3.388,3.388a.638.638,0,0,0,.9,0l8.091-8.091A.638.638,0,0,0,12.567,68.184Z"
                    transform="translate(0 -67.998)"
                    fill="#7284FA"
                    stroke="#7284FA"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const rightArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.564" height="11.086" viewBox="0 0 20.564 11.086">
        <g id="Group_2154" data-name="Group 2154" transform="translate(-1752.544 -316.279)">
            <line
                id="Line_363"
                data-name="Line 363"
                x2="18.753"
                transform="translate(1753.294 322.232)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_364"
                data-name="Line 364"
                x2="4.892"
                y2="4.892"
                transform="translate(1767.155 317.34)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_365"
                data-name="Line 365"
                y1="4.077"
                x2="4.892"
                transform="translate(1767.155 322.232)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const compliancePlatformLogo = (
    <svg id="Group_14285" data-name="Group 14285" xmlns="http://www.w3.org/2000/svg" width="523.147" height="125" viewBox="0 0 523.147 115">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_17486" data-name="Rectangle 17486" width="523.147" height="115" fill="none" />
            </clipPath>
        </defs>
        <rect id="Rectangle_17484" data-name="Rectangle 17484" width="15.908" height="83.482" transform="translate(48.141)" fill="#040405" />
        <g id="Group_14284" data-name="Group 14284">
            <g id="Group_14283" data-name="Group 14283" clipPath="url(#clip-path)">
                <path
                    id="Path_39385"
                    data-name="Path 39385"
                    d="M141.438,55.278h-45.8q.339,8.351,4.512,12.3a14.517,14.517,0,0,0,10.379,3.947,15.059,15.059,0,0,0,9.194-2.707,11.987,11.987,0,0,0,4.682-7.333h16.81a26.9,26.9,0,0,1-5.3,11.733,27.7,27.7,0,0,1-10.435,8.122,35.751,35.751,0,0,1-30.4-.959A27.354,27.354,0,0,1,84.016,69.268a34.96,34.96,0,0,1-3.948-17.036,35.286,35.286,0,0,1,3.948-17.091A26.916,26.916,0,0,1,95.072,24.029a33.673,33.673,0,0,1,16.245-3.836,32.943,32.943,0,0,1,16.189,3.836,27.057,27.057,0,0,1,10.717,10.548A30.622,30.622,0,0,1,142,49.863a30.127,30.127,0,0,1-.565,5.415m-19.46-18.614a15.276,15.276,0,0,0-10.661-3.948,15.63,15.63,0,0,0-10.83,3.948q-4.4,3.951-4.851,11.621h30.347q.337-7.673-4.005-11.621"
                    fill="#040405"
                />
                <path
                    id="Path_39386"
                    data-name="Path 39386"
                    d="M196.039,23.917a20.279,20.279,0,0,1,8.01,10.04V20.984h15.794V83.708A34.7,34.7,0,0,1,216.4,99.333a25.381,25.381,0,0,1-10.266,10.886q-6.828,3.948-16.527,3.949-13.539,0-21.886-6.431A26.594,26.594,0,0,1,157.57,90.251h15.681A12.931,12.931,0,0,0,178.5,97.64a17.379,17.379,0,0,0,9.984,2.652q6.995,0,11.282-4.118t4.287-12.466V70.4a20.569,20.569,0,0,1-8.01,10.1A23.754,23.754,0,0,1,182.5,84.272a26.834,26.834,0,0,1-14.214-3.836,25.892,25.892,0,0,1-9.871-11.112,38.523,38.523,0,0,1-3.554-17.091,38.523,38.523,0,0,1,3.554-17.091,25.912,25.912,0,0,1,9.871-11.113A26.861,26.861,0,0,1,182.5,20.194a24.024,24.024,0,0,1,13.538,3.723m-20.532,15q-4.514,4.851-4.513,13.312t4.513,13.256q4.511,4.8,11.958,4.794a15.744,15.744,0,0,0,11.9-4.907q4.68-4.908,4.682-13.143,0-8.348-4.682-13.256a15.744,15.744,0,0,0-11.9-4.907q-7.446,0-11.958,4.851"
                    fill="#040405"
                />
                <path
                    id="Path_39387"
                    data-name="Path 39387"
                    d="M277.038,23.917a20.279,20.279,0,0,1,8.01,10.04V20.984h15.794v62.5H285.048V70.4a20.569,20.569,0,0,1-8.01,10.1A23.754,23.754,0,0,1,263.5,84.272a26.834,26.834,0,0,1-14.214-3.836,25.9,25.9,0,0,1-9.872-11.112,38.551,38.551,0,0,1-3.553-17.091,38.551,38.551,0,0,1,3.553-17.091,25.92,25.92,0,0,1,9.872-11.113A26.861,26.861,0,0,1,263.5,20.194a24.024,24.024,0,0,1,13.538,3.723m-20.532,15q-4.514,4.851-4.512,13.312t4.512,13.256q4.51,4.8,11.958,4.794a15.744,15.744,0,0,0,11.9-4.907q4.68-4.908,4.682-13.143,0-8.348-4.682-13.256a15.744,15.744,0,0,0-11.9-4.907q-7.446,0-11.958,4.851"
                    fill="#040405"
                />
                <rect id="Rectangle_17485" data-name="Rectangle 17485" width="15.907" height="83.482" transform="translate(320.019)" fill="#040405" />
                <path
                    id="Path_39388"
                    data-name="Path 39388"
                    d="M24.743,71.77A12.372,12.372,0,1,1,12.372,59.4,12.371,12.371,0,0,1,24.743,71.77"
                    fill="#72b1e3"
                />
            </g>
        </g>
    </svg>
);
