export interface CustomerAdminWebSettings {
    apiBaseUrl: string;
    mainSiteBaseUrl: string;
    idpAuthority: string;
    idpClientId: string;
    compliancePlatformUrl: string;
}

export function getSettings(): CustomerAdminWebSettings {
    if (window.location.host.startsWith("localhost")) {
        return {
            apiBaseUrl: "https://localhost:7280",
            mainSiteBaseUrl: "http://localhost:3002",
            idpAuthority: "https://id-test.dotlegal.com",
            idpClientId: "CustomerAdminWebSPALocalhost",
            compliancePlatformUrl: "http://localhost:3001",
        };
    } else {
        const isTest = window.location.host === getEnvVariable("VITE_APP_CUSTOMER_ADMIN_URL_TEST");
        if (isTest) {
            const apiUrl = "https://" + getEnvVariable("VITE_APP_CUSTOMER_ADMIN_URL_TEST");
            const compliancePlatformUrl = "https://" + getEnvVariable("VITE_APP_PRIVACY_URL_TEST");
            return {
                apiBaseUrl: apiUrl,
                mainSiteBaseUrl: apiUrl,
                idpAuthority: getEnvVariable("VITE_APP_IDP_AUTHORITY_TEST"),
                idpClientId: getEnvVariable("VITE_APP_IDP_CLIENT_ID_TEST"),
                compliancePlatformUrl: compliancePlatformUrl,
            };
        }
        const isProd = window.location.host === getEnvVariable("VITE_APP_CUSTOMER_ADMIN_URL_PROD");
        if (isProd) {
            const apiUrl = "https://" + getEnvVariable("VITE_APP_CUSTOMER_ADMIN_URL_PROD");
            const compliancePlatformUrl = "https://" + getEnvVariable("VITE_APP_PRIVACY_URL_PROD");
            return {
                apiBaseUrl: apiUrl,
                mainSiteBaseUrl: apiUrl,
                idpAuthority: getEnvVariable("VITE_APP_IDP_AUTHORITY_PROD"),
                idpClientId: getEnvVariable("VITE_APP_IDP_CLIENT_ID_PROD"),
                compliancePlatformUrl: compliancePlatformUrl,
            };
        }
        throw new Error("Unknown window.location.host: " + window.location.host);
    }
}

function getEnvVariable(key: string) {
    return import.meta.env[key] ?? `No env variable matching ${key}`;
}
