import { PaymentInformationFormProps } from "./PaymentInformationForm.tsx";
import { useTranslation } from "../../../localization/useTranslation.ts";

export function usePaymentInformationForm(props: PaymentInformationFormProps) {
    const { translateCountry } = useTranslation();

    const formUpdater = {
        onAddressChange: (address: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.address = address;
            props.onChange(saveModel);
        },
        onPostalCodeChange: (postalCode: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.postalCode = postalCode;
            props.onChange(saveModel);
        },
        onCityChange: (city: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.city = city;
            props.onChange(saveModel);
        },
        onCountryChange: (countryCode: string | null) => {
            const saveModel = { ...props.saveModel! };
            saveModel.country = countryCode ? translateCountry(countryCode) : undefined;
            saveModel.countryCode = countryCode ?? undefined;
            props.onChange(saveModel);
        },
        onVATChange: (vatNumber: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.legalVATNumber = vatNumber;
            props.onChange(saveModel);
        },
        onFullNameChange: (fullName: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.fullName = fullName;
            props.onChange(saveModel);
        },
        onBillingEmailChange: (email: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.email = email;
            props.onChange(saveModel);
        },
        onCurrencyChange: (currency: string) => {
            const saveModel = { ...props.saveModel };
            saveModel.currency = Number(currency);
            props.onChange(saveModel);
        },
    };

    return { formUpdater };
}
