import { useState } from "react";
import { IPlanStep } from "./PlanStep";
import { PlanType, PurchaseSaveModel } from "../Purchase.types.ts";

export default function usePlanStepHooks(saveModel: PurchaseSaveModel, onChange: (saveModel: PurchaseSaveModel) => void) {
    const [selectedPlanToTrial, setSelectedPlanToTrial] = useState<PlanType | undefined>();

    function onSelectedPlanChange(planType: PlanType) {
        let temp = { ...saveModel };
        if (saveModel.selectedPlans.includes(planType)) {
            temp.selectedPlans = saveModel.selectedPlans.filter((type) => type !== planType);
        } else {
            temp.selectedPlans.push(planType);
        }

        onChange(temp);
    }

    return { onSelectedPlanChange, selectedPlanToTrial, setSelectedPlanToTrial };
}
