import { DotLegalButton, DotLegalHeader, DotLegalLink, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import { useTrialDialogStyle } from "./TrialDialog.styles";
import { useTranslation } from "../../../localization/useTranslation";
import { useTrialDialog } from "./TrialDialog.hooks.ts";
import { PlanType } from "../../Purchase.types.ts";

export interface ITrialDialogProps {
    onDialogClose: () => void;
    planType: PlanType;
    platformId: string;
    refetch: () => void;
}

function TrialDialog(props: ITrialDialogProps) {
    const styles = useTrialDialogStyle();
    const isOnSmallScreen = useIsOnSmallScreen();
    const { translatePlanType } = useTranslation();
    const { beginTrial, inProgress, showTrialCreatedContent, returnUrl } = useTrialDialog(props);
    const btnSize = 180;

    const onCloseDialog = () => {
        if (!inProgress) {
            props.onDialogClose();
        }
    };

    const contactDotLegal = () => {
        return (
            <>
                <DotLegalHeader marginTop={4} marginBottom={0} color="lightgrey" headerStyle="extraSmall" center>
                    If you have any questions please do not hesitate to contacts us at phone
                </DotLegalHeader>
                <DotLegalHeader color="lightgrey" headerStyle={"extraSmall"}>
                    <Box sx={styles.link} component={"a"} target="_blank" href="tel:+4570270127">
                        +45 7027 0127
                    </Box>{" "}
                    or mail{" "}
                    <Box sx={styles.link} component={"a"} href="mailto:support@dotlegal.com">
                        support@dotlegal.com
                    </Box>
                    .
                </DotLegalHeader>
            </>
        );
    };

    const getStartTrialContent = () => {
        return (
            <>
                <Box sx={styles.dialogSection}>
                    <Box sx={styles.dialogHeader}>
                        <DotLegalHeader center headerStyle="medium">
                            Start free trial: {translatePlanType(props.planType)}
                        </DotLegalHeader>
                    </Box>
                    <DotLegalHeader marginTop={-1.5} marginBottom={0} center headerStyle="small" color="secondary">
                        Try first and decide later. No credit card required.
                    </DotLegalHeader>

                    <DotLegalHeader marginTop={5} marginBottom={3} center headerStyle="small" fontWeight={400}>
                        Get a chance to explore all the features in {translatePlanType(props.planType)} in 14-days before choosing whether to upgrade your plan.
                    </DotLegalHeader>
                    <DotLegalHeader marginBottom={3} center headerStyle="small" fontWeight={400}>
                        Click{" "}
                        <Box
                            sx={(theme) => ({ color: theme.palette.primary.main })}
                            component={"a"}
                            target="_blank"
                            href="https://www.dotlegal.com/da/priser#alle-features"
                        >
                            here
                        </Box>{" "}
                        to see the full list of features included in {translatePlanType(props.planType)}.
                    </DotLegalHeader>
                    <DotLegalHeader center headerStyle="small" marginBottom={3} fontWeight={400}>
                        If you choose to start a free trial you can start to use the new features immediately. After your 14-day trial ends, you can either
                        choose to upgrade your plan or stay on the current plan. You can also upgrade your plan during your free trial.
                    </DotLegalHeader>

                    <DotLegalHeader center headerStyle="small" fontWeight={400}>
                        Please note that features available during the trial will be locked once the trial period ends. After that, access to view or edit this
                        data will be restricted unless you upgrade your plan.
                    </DotLegalHeader>
                </Box>
                <Box sx={styles.dialogSection}>
                    <Box sx={styles.btnWrapper}>
                        <DotLegalButton buttonType="primary" onClick={() => beginTrial()} isLoading={inProgress} btnLength={btnSize}>
                            Start free trial
                        </DotLegalButton>
                    </Box>

                    {contactDotLegal()}
                </Box>
            </>
        );
    };

    const getTrialCreatedContent = () => {
        return (
            <>
                <Box sx={styles.dialogSection}>
                    <Box sx={styles.dialogHeader}>
                        <DotLegalHeader center headerStyle="medium">
                            Your {translatePlanType(props.planType)} trial has been created
                        </DotLegalHeader>
                    </Box>

                    <DotLegalHeader center headerStyle="small" fontWeight={400} marginTop={4}>
                        Your free trial for {translatePlanType(props.planType)} has been created. If your wish to try another plan, you can close the dialog and
                        choose another plan from the list. You can return to .legal GRC platform and try out your new trial by clicking &quot;Return to .legal
                        GRC platform&quot;.
                    </DotLegalHeader>
                </Box>
                <Box sx={styles.dialogSection}>
                    <Box sx={styles.btnWrapper}>
                        <DotLegalButton buttonType="primary" onClick={() => onCloseDialog()} isLoading={inProgress} btnLength={btnSize}>
                            Try another plan
                        </DotLegalButton>
                        <Box sx={(theme) => ({ "& a": { fontSize: theme.typography.pxToRem(13) } })}>
                            <DotLegalLink linkColor={"primary"} to={returnUrl} openExternalLinkInSameTab>
                                Return to .legal GRC platform
                            </DotLegalLink>
                        </Box>
                    </Box>

                    {contactDotLegal()}
                </Box>
            </>
        );
    };

    return (
        <Dialog open onClose={() => onCloseDialog()} fullScreen={isOnSmallScreen} maxWidth={"md"} fullWidth sx={styles.dialog}>
            <Box sx={styles.closeButtonWrapper} visibility={inProgress ? "hidden" : "visible"}>
                <IconButton aria-label="close" onClick={() => onCloseDialog()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828">
                        <g id="Group_967" data-name="Group 967" transform="translate(-1108.086 -210.086)">
                            <line
                                id="Line_169"
                                data-name="Line 169"
                                x2="12"
                                y2="12"
                                transform="translate(1109.5 211.5)"
                                fill="none"
                                stroke="#183683"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <line
                                id="Line_170"
                                data-name="Line 170"
                                x1="12"
                                y2="12"
                                transform="translate(1109.5 211.5)"
                                fill="none"
                                stroke="#183683"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                </IconButton>
            </Box>
            <DialogContent sx={styles.dialogContent}>{showTrialCreatedContent ? getTrialCreatedContent() : getStartTrialContent()}</DialogContent>
        </Dialog>
    );
}

export default TrialDialog;
