import { Box, Grid } from "@mui/material";
import { PlanCostModelViewModel, PurchaseSaveModel } from "../Purchase.types.ts";
import { DotLegalPaper, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import PaymentInformationForm from "./paymentInformationForm/PaymentInformationForm.tsx";
import PaymentSummery from "./paymentSummery/PaymentSummery.tsx";
import React from "react";

export interface PaymentInformationStepProps {
    companyName: string;
    saveModel: PurchaseSaveModel;
    plans: Array<PlanCostModelViewModel>;
    onChange: (saveModel: PurchaseSaveModel) => void;
}

function PaymentInformationStep(props: PaymentInformationStepProps) {
    const isOnSmallScreen = useIsOnSmallScreen();

    return (
        <DotLegalPaper removePadding>
            <Grid container sx={{ p: "8px", display: isOnSmallScreen ? "block" : "flex" }}>
                <Grid item sm={12} md={6} sx={{ p: isOnSmallScreen ? 0 : "10px", pr: isOnSmallScreen ? 0 : "80px" }} mb={isOnSmallScreen ? 4 : 0}>
                    <PaymentInformationForm saveModel={props.saveModel} onChange={props.onChange} companyName={props.companyName} />
                </Grid>
                <Grid item sm={12} md={6}>
                    <PaymentSummery selectedPlans={props.saveModel.selectedPlans} allPlans={props.plans} currency={props.saveModel.currency!} />
                </Grid>
            </Grid>
        </DotLegalPaper>
    );
}
export default PaymentInformationStep;
